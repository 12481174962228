<template>
    <v-app :class="bodyClass">
        <template v-if="isLoaded">
            <the-alert />
            <the-app-bar v-if="isLoggedIn" @menu-toggled="menuOpen = true" />
            <v-main :class="{ 'main': isLoggedIn }">
                <router-view class="router-container" />
            </v-main>
        </template>
        <template v-else>
            <div class="loading d-flex align-center justify-center flex-grow-1">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    indeterminate
                    color="tertiary" />
            </div>
        </template>

        <v-navigation-drawer
            v-if="isLoggedIn"
            v-model="menuOpen"
            right
            light
            temporary
            absolute>
            <v-list>
                <v-list-item
                    v-for="item in availableItems"
                    :key="item.text"
                    class="list-item">
                    <router-link :to="item.route" class="router-link">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </router-link>
                </v-list-item>
                <v-list-item
                    class="list-item"
                    @click="logout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Log Out</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-app>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
    components: {
        theAlert: () => import("@/components/TheAlert"),
        theAppBar: () => import("@/components/TheAppBar")
    },
    data() {
        return {
            menuOpen: false,
            items: [
                {
                    text: "Generate Ad",
                    icon: "mdi-text-box-plus-outline",
                    route: "/generate"
                },
                {
                    icon: "mdi-comment-text-outline",
                    text: "My Vehicles",
                    route: "/my-vehicles"
                },
                {
                    icon: "mdi-database-import-outline",
                    text: "Import Vehicles",
                    route: "/vehicle-import"
                },
                {
                    icon: "mdi-animation-play-outline",
                    text: "Bulk Generate",
                    route: "/bulk-generate"
                },
                {
                    icon: "mdi-garage-open-variant",
                    text: "Manage Yard Assignment",
                    route: "/person-yards"
                },
                {
                    icon: "mdi-account-multiple",
                    text: "Manage Users",
                    route: "/users"
                },
                {
                    icon: "mdi-cog-outline",
                    text: "My Settings",
                    route: "/my-settings"
                }
            ]
        }
    },

    computed: {
        ...mapState({
            isLoggedIn: s => s.authentication.isLoggedIn,
            user: s => s.authentication.user,
        }),

        ...mapGetters({
            isLoaded: "load/isLoaded",
            hasPolicy: "authentication/hasPolicy"
        }),
        availableItems() {
            return this.items.filter(i => i.policy == null || this.hasPolicy(i.policy));
        },
        bodyClass() {
            return this.isLoggedIn ? 'logged-in' : 'not-logged-in';
        }
    },

    async mounted() {
        this.$router.onReady(this.onStartup)
    },

    methods: {
        ...mapActions({
            onStartup: "load/onStartup",
            logout: "authentication/logout"
        }),
        navigateToRoute(route) {
            this.$router.push(route);
            this.menuOpen = false;
        }
    }
};
</script>

<style lang="scss">
    @import "assets/style/theme.scss";

    body {
        height: 100%;
        background-color: $color-background;
    }
    #app {
        height: 100%;
        background-color: $color-background;
    }
    .not-logged-in {
        background-color: $color-primary;
    }

    .loading {
        background-color: $color-background;
    }

    .router-container {
        width: 100%;
        height: 100%;
        padding: 10px;
        background-color: $color-background;
    }

    .relative {
        position: relative;
    }

.v-card.v-sheet.theme--light {
    border-radius: 30px;
}
</style>

<style lang="scss" scoped>
    // v-main dynamically calculates some top padding after the page displays. Here we are
    // hardcoding the results of this calculation. This fixes issues with apex charts, where it
    // calculates the height of the chart before the v-main padding is determined. This also
    // prevents the whole page popping down by 48 pixels.
    .main {
        padding-top: 64px !important;
    }
    .router-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;
    }
    .list-item .router-link {
        flex-grow: 1;
    }
    .list-item:hover {
        background-color: #f2f2f2;
        cursor: pointer;
    }
</style>
