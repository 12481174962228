import Vue from "vue";
import Router from "vue-router";
import accountRoutes from "./accountRoutes";
import tableRoutes from "./tableRoutes";
import itemRoutes from "./itemRoutes";
import { parseQuery, stringifyQuery } from "@/services/queryStringService"
import { equal } from "@/services/filtering";

Vue.use(Router);

let routes = [
    {
        path: "/generate",
        name: "generateAd",
        component: () => import("@/features/ads/generateAd/GenerateAd.vue"),
    },
    {
        path: "/generate/:id",
        name: "generateAdWithId",
        component: () => import("@/features/ads/generateAd/GenerateAd.vue"),
    },
    {
        path: "/select-ad/:id",
        name: "selectAd",
        component: () => import("@/features/ads/selectAd/SelectAd.vue"),
    },
    {
        path: "/vehicle-ads",
        name: "vehicleAds",
        component: () => import("@/features/schemas/views/SchemaTableView.vue"),
        staticProps: {
            entityKey: "vehicleAd",
            filter: equal("isSelected", true, "bool")
        },
    },
    {
        path: "/fair-use-policy",
        name: "fairUsePolicy",
        component: () => import("@/features/landing/FairUsePolicy.vue"),
    },
    {
        path: "/privacy-policy",
        name: "privacyPolicy",
        component: () => import("@/features/landing/PrivacyPolicy.vue"),
    },
    {
        path: "/terms-of-service",
        name: "termsOfService",
        component: () => import("@/features/landing/TermsOfService.vue"),
    },
    {
        path: "/my-vehicles",
        name: "myAds",
        component: () => import("@/features/ads/myAds/MyAds.vue"),
    },
    {
        path: "/my-settings",
        name: "mySettings",
        component: () => import("@/features/account/views/Settings.vue"),
    },
    {
        path: "/vehicle-import",
        name: "vehicleImport",
        component: () => import("@/features/import/VehicleImport.vue"),
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import("@/features/admin/Index.vue"),
    },
    {
        path: "/bulk-generate",
        name: "bulkGenerate",
        component: () => import("@/features/import/BulkGenerate.vue"),
    },
    {
        path: "*",
        redirect: "/generate",
    },
];

routes = routes
    .concat(accountRoutes)
    .concat(tableRoutes)
    .concat(itemRoutes);

// Pass the query string and route parameters as props into all routes.
routes.forEach(addProps);

function addProps(r) {
    r.props = route => ({
        ...route.params,
        ...route.query,
        ...r.staticProps
    });

    r.children?.forEach(addProps);
}

const router = new Router({
    mode: "history",
    parseQuery,
    stringifyQuery,
    routes: routes
});

export default router;
