<template>
    <div class="app-search">
        <v-textarea
            v-if="!isEditing"
            key="displayField"
            class="app-search-item-display"
            v-bind="$attrs"
            :value="display"
            :label="label"
            :hide-details="hideDetails"
            autocomplete="off"
            outlined
            dense
            rounded
            rows="1"
            auto-grow
            :autofocus="false"
            :append-icon="closeIcon"
            @click:append="remove"
            @focus="edit" />
        <app-text-field
            v-if="isEditing"
            ref="editField"
            key="editField"
            v-model="searchText"
            v-bind="$attrs"
            :label="label"
            :hide-details="hideDetails"
            autocomplete="off"
            autofocus
            rounded
            @blur="clear"
            @keydown="onKeyDown" />

        <v-menu
            :value="menuVisible"
            :close-on-click="false"
            :close-on-content-click="false"
            :disable-keys="true"
            :open-on-click="false"
            max-height="304">
            <template #activator="{ on }">
                <div :style="activatorStyle" @null="on" />
            </template>
            <v-list class="app-search-results">
                <v-list-item
                    v-for="(item, index) in items"
                    :key="item.id"
                    link
                    :class="itemClass(index)"
                    @mousedown="onItemClick(item)">
                    <v-list-item-title>
                        {{ item.label }}
                    </v-list-item-title>
                    <v-list-item-action v-if="isSelected(item)">
                        <v-icon @click.stop="removeItem(item)">
                            mdi-close
                        </v-icon>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
import { isNullOrWhiteSpace, trim } from "@/services/stringUtility";
import { buildSelectQuery } from "@/features/schemas/services/searcher"
import { getTableData } from "@/features/schemas/services/tableService";
import { getEntity } from "@/features/schemas/services/schemaProvider";
import { get } from "@/features/schemas/services/schemaApi";
import { getLabel } from "@/features/schemas/services/labeller";
import { debounce } from "@/services/debounce";

export default {
    props: {
        entityKey: {
            type: String,
            default: null,
            required: true
        },
        value: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: () => "Search"
        },
        filter: {
            type: Object,
            default: null,
        },
        searchOptions: {
            type: Object,
            default: null,
        },
        searchWhenBlank: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isEditing: false,
            searchText: null,
            results: null,
            selectedIndex: -1,
            selectedItems: [],
            isSelecting: false,
            showMenu: false
        }
    },
    computed: {
        items() {
            return this.results?.items ?? [];
        },
        display() {
            if (this.selectedItems.length === 0) {
                return "";
            }

            return this.selectedItems.map(item => item.label).join(', ');
        },
        activatorStyle() {
            if(this.hideDetails) {
                return null;
            }
            return {
                marginTop: "-26px",
                height: "26px"
            };
        },
        closeIcon() {
            return this.value && this.value.length > 0 ? "mdi-close" : null;
        },
        searchIsBlank() {
            return isNullOrWhiteSpace(trim(this.searchText, ""));
        },
        menuVisible() {
            return this.showMenu && this.items && this.items.length;
        }
    },
    watch: {
        searchText: debounce(function () {
            return this.search();
        }, 250),
        selectedItems(selectedItems) {
            // Prevents the input event from firing on initialization.
            if (this.isSelecting) {
                this.isSelecting = false;
                return;
            }
            if (this.value && JSON.stringify(this.value.map(item => item.id)) !==
                JSON.stringify(selectedItems.map(item => item.id))) {
                this.$emit("input", selectedItems.map(item => item.id));
                this.$emit("items", selectedItems);
            }
        },
        value: {
            immediate: true,
            async handler(value) {
                if (!value.length) {
                    this.searchText = null;
                    this.selectedItems = [];
                    this.showMenu = false;
                    return;
                }
                let items = await Promise.all(value.map(id => get(this.entityKey, id)));
                this.isSelecting = true;
                this.selectedItems = items.map(item => ({
                    ...item,
                    label: getLabel(this.entityKey, item)
                }));
            }
        }
    },
    methods: {
        edit() {
            this.isEditing = true;
            this.showMenu = true;
            this.search();
        },
        async search() {
            if (!this.searchWhenBlank && this.searchIsBlank) {
                this.results = null;
                return;
            }

            let searchText = this.searchText;

            let options = {
                filter: this.filter,
                sortBy: this.searchOptions?.sortBy,
                direction: this.searchOptions?.direction
            };

            let model = buildSelectQuery(this.entityKey, searchText, options);

            let results = await getTableData(this.entityKey, model);

            results.items.forEach(item => item.label = getLabel(this.entityKey, item));
            results.headers.push({
                value: "label",
                text: getEntity(this.entityKey).singleTitle
            });

            // Avoid showing previous search if results arrive out of order.
            if (searchText === this.searchText) {
                this.results = results;
            }
        },
        onKeyDown(e) {
            let handledKeys = ["ArrowDown", "ArrowUp", "Enter", "Tab"];

            if (handledKeys.includes(e.key)) {
                let handler = this[`on${e.key}`];
                handler(e);
            }
        },
        onArrowDown(e) {
            let index = this.selectedIndex + 1;
            if (index < this.items.length) {
                this.selectedIndex = index;
            }
            e.preventDefault();
        },
        onArrowUp(e) {
            let index = this.selectedIndex - 1;
            if (index > -1) {
                this.selectedIndex = index;
            }
            e.preventDefault();
        },
        onEnter(e) {
            if (this.selectedIndex > -1) {
                this.toggleItem(this.items[this.selectedIndex]);
                this.clear();
            }
            e.preventDefault();
        },
        onTab() {
            if (this.selectedIndex > -1) {
                this.toggleItem(this.items[this.selectedIndex]);
                this.clear();
            }
        },
        onItemClick(item) {
            this.toggleItem(item);
            this.clear();
        },
        clear() {
            this.results = null;
            this.selectedIndex = -1;
            this.isEditing = false;
            this.showMenu = false;
        },
        remove() {
            this.selectedItems = [];
            this.searchText = null;
            this.clear();
        },
        itemClass(index) {
            if (index == this.selectedIndex) {
                return "app-search-selected";
            }
        },
        toggleItem(item) {
            let index = this.selectedItems.findIndex(selected => selected.id === item.id);
            if (index > -1) {
                this.selectedItems.splice(index, 1);
            } else {
                this.selectedItems.push(item);
            }
        },
        isSelected(item) {
            return this.selectedItems.some(e => e.id === item.id);
        },
        removeItem(itemToRemove) {
            this.selectedItems = this.selectedItems.filter(item => item.id !== itemToRemove.id);
            this.$emit("input", this.selectedItems.map(item => item.id));
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "@/styles/theme.scss";

    .app-search-selected {
        color: $color-primary;
        background-color: rgba($color-primary, 0.16);
    }

    .app-search-results > * {
        cursor: pointer;
    }
</style>
