<template>
    <div>
        <v-progress-circular
            v-if="isLoading"
            class="progress-spinner"
            :size="40"
            indeterminate
            color="primary" />
        <v-file-input
            v-else
            class="file-input"
            prepend-icon="mdi-plus"
            hide-input
            rounded
            @change="addFile" />
    </div>
</template>

<script>
import { add } from '../services/apiService';
export default{
    data() {
        return {
            isLoading: false
        }
    },

    methods: {
        async addFile(file) {
            this.isLoading = true;
            if(!file) {
                return;
            }

            let formData = new FormData();
            formData.append("file", file);

            let response = await add("files", formData)
            this.$emit('input', response);
            this.isLoading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/theme.scss";

.file-input {
    border: 1px solid $color-grey-dark;
    border-radius: 4px;
    padding-top: 0px;
    padding-left: 8px;
}

.progress-spinner{
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
}
</style>
